import React from 'react'
import PropTypes from 'prop-types'

import CardTitle from 'components/card/CardTitle';
import CardText from 'components/card/CardText';
import CardButton from 'components/card/CardButton';

import { StyledCardBody } from 'components/card/CardBody/styled';

function CardBody({ 
  headingText, 
  cardText, 
  buttonComponent, 
  buttonAlign, 
  desktopOneRowCard, 
  alignImage,
  sizeSmall,
  headingTextAlign,
  textAlign,
  cardList,
  isFakeCard,
  isJetCardProduct,
  textMarginBotttom,
  isBlog,
  isFeaturedBlogCard,
  additionalButtonStyle,
  isCountryCards,
  isFxAirAnnouncementSlice,
}) {
  return (
    <StyledCardBody
      desktopOneRowCard={desktopOneRowCard}
      sizeSmall={sizeSmall}
      buttonComponent={buttonComponent}
      isFakeCard={isFakeCard}
      isBlog={isBlog}
      isFeaturedBlogCard={isFeaturedBlogCard}
      isFxAirAnnouncementSlice={isFxAirAnnouncementSlice}
    >
      {headingText && (
        <CardTitle 
          headingText={headingText} 
          headingTextAlign={headingTextAlign}
          fontSize="24px"
          sizeSmall={sizeSmall}
          isBlog={isBlog}
          isFeaturedBlogCard={isFeaturedBlogCard}
        />
      )}

      <CardText
        text={cardText}
        textAlign={textAlign}
        desktopOneRowCard={desktopOneRowCard}
        alignImage={alignImage}
        sizeSmall={sizeSmall}
        buttonComponent={buttonComponent}
        isJetCardProduct={isJetCardProduct}
        textMarginBotttom={textMarginBotttom}
        isBlog={isBlog}
        isFeaturedBlogCard={isFeaturedBlogCard}
        isCountryCards={isCountryCards}
      />
      
      {buttonComponent && (
        <CardButton 
          buttonComponent={buttonComponent}
          buttonAlign={buttonAlign}
          additionalButtonStyle={additionalButtonStyle}
        />
      )}

      {cardList}
      
    </StyledCardBody>
  )
}

CardBody.propTypes = {
  additionalButtonStyle: PropTypes.object,
  alignImage: PropTypes.string, 
  buttonAlign: PropTypes.string, 
  buttonComponent: PropTypes.object, 
  cardList: PropTypes.object, 
  cardText: PropTypes.string, 
  desktopOneRowCard: PropTypes.bool, 
  headingText: PropTypes.string, 
  headingTextAlign: PropTypes.string, 
  isBlog: PropTypes.bool,
  isCountryCards: PropTypes.bool,
  isFakeCard: PropTypes.bool,
  isFeaturedBlogCard: PropTypes.bool, 
  isFxAirAnnouncementSlice: PropTypes.bool,
  isJetCardProduct: PropTypes.bool, 
  sizeSmall: PropTypes.bool, 
  textAlign: PropTypes.string,
  textMarginBotttom: PropTypes.string,
}

export default CardBody

