import styled, { css } from 'styled-components';

export const StyledCardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  ${props => props.shouldZoom === true &&
    css`
      .maginfy-glass-calendar-svg {
        display: none;
      }
    `
  }


  ${props => props.sizeSmall &&
    css`
      height: 170px;
      min-height: 140px;
    `
  }

  ${props => props.isFakeCard &&
    css`
      height: 0;
      margin: 0;
    `
  }

  ${props => 
    props.hideImageInMobile &&
      css`
        display: none;

        @media(min-width: 640px) {
          display: block;
        }
      `
  }

  ${props => 
    props.desktopOneRowCard && props.shouldZoom === true &&
      css`
        position: fixed;
        width: 100%;
        height: 50vh;
        max-height: 1300px;
        max-width: 1600px;
        top: 25vh;
        right: 0;
        z-index: 20000;

        @media(min-width: 640px) { 
          width: 100vw;
          height: 80vh;
          top: 10vh;
          right: 0;
        }

        @media(min-width: 1024px) { 
          width: 80vw;
          height: 80vh;
          top: 10vh;
          right: 10vw;
        }

        @media(min-width: 1200px) {
          width: 60vw;
          height: 100vh;
          top: 0;
          right: 20vw;
        }

        @media screen and (min-width:320px) and (max-width:900px) and (orientation:landscape) {
          width: 60%;
          height: 100vh;
          top: 0;
          right: 20%;
        }
    `
  }

  
  ${props =>
    props.desktopOneRowCard && props.shouldZoom === false &&
    css`
      width: ${ props => 
        (props.isBlog && !props.isFeaturedBlogCard) || 
        props.isFxAirAnnouncementSlice ? "calc(100% - 50px)" 
        : "calc(100% - 20px);"
      }; 

      box-shadow: ${props => 
        props.zoomOption === true || 
        props.isFxAirAnnouncementSlice ? "none" 
        : "-10px 10px 0px 0px rgba(212,214,222,1)"
      };
      
      margin-left:  ${props => 
        (props.isHomePage && props.isFxAirAnnouncementSlice) ? "50px" :
        (props.zoomOption === true || props.isFxAirAnnouncementSlice) ? "0"
        : "10px"
      };
      
      max-width: 338px;
      
      height: ${props => props.isBlog && !props.isFeaturedBlogCard ? "170px" : props.zoomOption === true && "340px"}; ;

      @media(min-width: 768px) {
        max-width: ${props => 
          props.zoomOption === true ? "440px" 
          : props.isFxAirAnnouncementSlice ? "338px" 
          : "unset"
        };
        
        width: ${ props => 
          (props.isBlog && !props.isFeaturedBlogCard) 
          || props.isFxAirAnnouncementSlice ? "calc(45% - 50px)" 
          : "calc(50% - 20px)"
        }; 
        
        height: ${ props => 
          props.zoomOption === true ? "100%" 
          : (props.isBlog && !props.isFeaturedBlogCard) || props.isFxAirAnnouncementSlice ? "170px" 
          : "clamp(170px, 25vw, 340px)"
        };

        box-shadow: ${props => 
          props.zoomOption === true 
          || props.isFxAirAnnouncementSlice ? "none" 
          : "-20px 20px 0px 0px rgba(212,214,222,1)"
        };
        
        margin-left:  ${props => 
          (props.isHomePage && props.isFxAirAnnouncementSlice) ? "50px" :
          (props.zoomOption === true || props.isFxAirAnnouncementSlice) ? "0" 
          : "20px"
        };
      }

      @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
        max-width: 338px;
        margin-right: ${ props => props.isBlog && !props.isFeaturedBlogCard && "10px"};
        margin-left: ${ props => props.isBlog && props.isFeaturedBlogCard && "14px"};
      }
    `
  }

  button {
    background: #3C3C3B !important;
  }

  img {
    height: inherit;
    width: 100%;

    ${props => props.sizeSmall &&
      css`
        height: 100%;
      `
    }

    ${props => props.isFakeCard &&
      css`
        height: 0;
        margin: 0;
      `
    }

    ${props => props.desktopOneRowCard && (props.isBlog || props.objectFitCover) &&
      css`
        object-fit: cover;
      `
    }
  }
`