import React from 'react'
import PropTypes from 'prop-types'

import CardImage from 'components/card/CardImage';
import CardBody from 'components/card/CardBody';

import { StyledCard, StyledCardLink } from 'components/card/styled';

function Card({ 
  displayText, 
  displayList, 
  displayHeading, 
  buttonComponent, 
  buttonAlign, 
  cardText, 
  headingText, 
  cardImageUrl,
  hasSeperator,
  desktopOneRowCard,
  alignImage,
  hideImageInMobile,
  sizeSmall,
  headingTextAlign, 
  textAlign,
  zoomOption,
  zoomButton,
  zoomButtonText,
  cardList,
  isCardLink,
  cardLink,
  className,
  isCarousel,
  isFakeCard,
  isFxAirAnnouncementSlice,
  isHomePage,
  isJetCardProduct,
  textMarginBotttom,
  lastCardMargin,
  isBlog,
  isFeaturedBlogCard,
  language,
  margin,
  imageAltText,
  objectFitCover,
  additionalButtonStyle,
  isCountryCards,
  fxAirLogoUrl,
}) {

  if (isCardLink) {
    return (
      <StyledCardLink 
        hasSeperator={hasSeperator}
        desktopOneRowCard={desktopOneRowCard}
        alignImage={alignImage}
        hideImageInMobile={hideImageInMobile}
        sizeSmall={sizeSmall}
        zoomOption={zoomOption}
        path={cardLink} 
        className={className}
        isCarousel={isCarousel}
        language={language}
        buttonComponent={buttonComponent}
        isFakeCard={isFakeCard}
        isJetCardProduct={isJetCardProduct}
        lastCardMargin={lastCardMargin}
        isBlog={isBlog}
        isFeaturedBlogCard={isFeaturedBlogCard}
        linkComponent={
          <React.Fragment>
            <CardImage 
              imageSrc={cardImageUrl}
              imageAltText={imageAltText || ""}
              hideImageInMobile={hideImageInMobile}
              desktopOneRowCard={desktopOneRowCard}
              zoomOption={zoomOption}
              zoomButton={zoomButton}
              zoomButtonText={zoomButtonText}
              isFakeCard={isFakeCard}
              sizeSmall={sizeSmall}
              isBlog={isBlog}
              isFeaturedBlogCard={isFeaturedBlogCard}
              objectFitCover={objectFitCover}
            />
            <CardBody
              additionalButtonStyle={additionalButtonStyle}
              isCountryCards={isCountryCards}
              displayText={displayText}
              displayList={displayList}
              displayHeading={displayHeading} 
              headingText={headingText}
              buttonComponent={buttonComponent}
              buttonAlign={buttonAlign}
              cardText={cardText}
              desktopOneRowCard={desktopOneRowCard}
              alignImage={alignImage}
              sizeSmall={sizeSmall}
              headingTextAlign={headingTextAlign}
              textAlign={textAlign}
              cardList={cardList}
              isFakeCard={isFakeCard}
              isJetCardProduct={isJetCardProduct}
              textMarginBotttom={textMarginBotttom}
              isBlog={isBlog}
              isFeaturedBlogCard={isFeaturedBlogCard}
            />
          </React.Fragment>
      }
      />
    )
  } else {
    return (
      <StyledCard 
        hasSeperator={hasSeperator}
        desktopOneRowCard={desktopOneRowCard}
        alignImage={alignImage}
        hideImageInMobile={hideImageInMobile}
        sizeSmall={sizeSmall}
        zoomOption={zoomOption}
        className={className}
        buttonComponent={buttonComponent}
        isFakeCard={isFakeCard}
        isJetCardProduct={isJetCardProduct}
        lastCardMargin={lastCardMargin}
        isBlog={isBlog}
        isFeaturedBlogCard={isFeaturedBlogCard}
        margin={margin}
        isCountryCards={isCountryCards}
        isFxAirAnnouncementSlice={isFxAirAnnouncementSlice}
        isHomePage={isHomePage}
      >
        <CardImage 
          imageSrc={cardImageUrl}
          imageAltText={imageAltText || ""}
          hideImageInMobile={hideImageInMobile}
          desktopOneRowCard={desktopOneRowCard}
          zoomOption={zoomOption}
          zoomButton={zoomButton}
          zoomButtonText={zoomButtonText}
          isFakeCard={isFakeCard}
          sizeSmall={sizeSmall}
          isBlog={isBlog}
          isFeaturedBlogCard={isFeaturedBlogCard}
          objectFitCover={objectFitCover}
          isFxAirAnnouncementSlice={isFxAirAnnouncementSlice}
          isHomePage={isHomePage}
          fxAirLogoUrl={fxAirLogoUrl}
        />
        <CardBody
          additionalButtonStyle={additionalButtonStyle}
          isCountryCards={isCountryCards}
          displayText={displayText}
          displayList={displayList}
          displayHeading={displayHeading} 
          headingText={headingText}
          buttonComponent={buttonComponent}
          buttonAlign={buttonAlign}
          cardText={cardText}
          desktopOneRowCard={desktopOneRowCard}
          alignImage={alignImage}
          sizeSmall={sizeSmall}
          headingTextAlign={headingTextAlign}
          textAlign={textAlign}
          cardList={cardList}
          isFakeCard={isFakeCard}
          isJetCardProduct={isJetCardProduct}
          textMarginBotttom={textMarginBotttom}
          isBlog={isBlog}
          isFeaturedBlogCard={isFeaturedBlogCard}
          isFxAirAnnouncementSlice={isFxAirAnnouncementSlice}
        />
      </StyledCard>
    )
  }
}

Card.propTypes = {
  additionalButtonStyle: PropTypes.object,
  alignImage: PropTypes.string, 
  buttonAlign: PropTypes.string, 
  buttonComponent: PropTypes.object, 
  cardImageUrl: PropTypes.string, 
  cardLink: PropTypes.string, 
  cardList: PropTypes.object, 
  cardText: PropTypes.string, 
  className: PropTypes.string, 
  desktopOneRowCard: PropTypes.bool, 
  displayHeading: PropTypes.bool, 
  displayList: PropTypes.bool,  
  displayText: PropTypes.bool, 
  fxAirLogoUrl: PropTypes.string,
  hasSeperator: PropTypes.bool, 
  headingText: PropTypes.string, 
  headingTextAlign: PropTypes.string,
  hideImageInMobile: PropTypes.bool,
  imageAltText: PropTypes.string,
  isBlog: PropTypes.bool,
  isCardLink: PropTypes.bool,
  isCarousel: PropTypes.bool,
  isCountryCards: PropTypes.bool,
  isFakeCard: PropTypes.bool,
  isFeaturedBlogCard: PropTypes.bool,
  isFxAirAnnouncementSlice: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isJetCardProduct: PropTypes.bool,
  language: PropTypes.string,
  lastCardMargin: PropTypes.string,
  margin: PropTypes.string,
  objectFitCover: PropTypes.bool,
  sizeSmall: PropTypes.bool,
  textAlign: PropTypes.string,
  textMarginBotttom: PropTypes.string,
  zoomButton: PropTypes.object,
  zoomButtonText: PropTypes.string,
  zoomOption: PropTypes.bool,
}

export default Card

